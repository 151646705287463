<template>
      <section>
            <div v-if="cart.items && cart.items.length > 0" :class="productWrapper">
                  <div class="product__table" v-for="(product, index) in cart.items" :key="product.id">
                        <div class="product__table-imgWrapper">
                              <router-link :to="'/praemien/produkt/' + product?.seo_url" :title="t('LBL_CONTACT')">
                                    <app-image :alt="`${product?.name}`"
                                          class="flex flex-col justify-center items-center product__table-imgWrapper-image"
                                          v-show="product.image"
                                          :src="typeof product.image === 'string' ? product.image : ''" />
                              </router-link>
                        </div>

                        <div class="product__table-desc">
                              <router-link :to="'/praemien/produkt/' + product?.seo_url" :title="t('LBL_CONTACT')">
                                    <h2 class="text-blue">{{ product.name }}</h2>
                              </router-link>

                              <div v-html="product.short_description"></div>
                        </div>
                        <div class="text-right product__table-deliveryinfo"
                              v-if="product.delivery_info && (product?.delivery_info?.delivery_text || product?.delivery_info?.status_text)">
                              <p v-if="product?.product_group_name === 'PAYBACK_ARTICLE'" :class="{
                                    'text-green-light': product.availability_info.status_id === 'ON_STOCK',
                                    'cart-item-status-red': product.availability_info.status_id === 'NO_STOCK'
                              }">
                                    {{ product?.delivery_info?.delivery_text }}
                              </p>
                              <p v-else :class="{
                                    'text-green-light': product.availability_info.status_id === 'ON_STOCK',
                                    'cart-item-status-red': product.availability_info.status_id === 'NO_STOCK'
                              }">
                                    {{ product.availability_info.status_text }}
                                    {{ product.delivery_info.delivery_text }}
                              </p>
                              <div class="flex flex-col justify-items-end xs:flex xxs:flex sm:flex md:hidden lg:hidden">
                                    <span v-if="product?.sold_by != product?.shipped_by" class="grid">
                                          <span v-if="product.sold_by">Verkauf durch: <span class="text-blue">{{
                                                product.sold_by }} </span></span>

                                          <span v-if="product.shipped_by">Versand durch: <span class="text-blue">{{
                                                product.shipped_by }}</span></span>
                                    </span>

                                    <span
                                          v-if="product?.sold_by && product?.shipped_by && product.sold_by != '' && product.shipped_by != '' && product?.sold_by === product?.shipped_by">Verkauf
                                          und Versand durch: <span class="text-blue">{{ product.sold_by }} </span></span>
                              </div>
                        </div>
                        <div class="product__table-actions">
                              <div
                                    class="product__table-actions_subtable grid grid-cols-5 gap-2 grid-flow-row-dense border-t">
                                    <div class="product__table-actions_subtable-buttons">
                                          <div class="grid grid-cols-3 gap-2 grid-flow-row-dense rounded-full flex flex-col justify-center items-center"
                                                :class="showActions ? 'bg-blue-lightest' : ''">
                                                <div v-if="showActions" class="minus-trash" :class="{ minus: product.qty > 1 }">
                                                      <app-image :alt="cart-remove" :data-rewardsshop-action="cart-remove"
                                                            :data-rewardsshop-cart-arrayposition="productArrayIndex"
                                                            :data-rewardsshop-cart-remove-location="route.meta.trackingName"
                                                            :data-rewardsshop-cart-remove-quantity="product?.qty"
                                                            v-if="product.qty > 1" @click="reduceQntyByOne(product)"
                                                            class="cursor-pointer minus" :src="require('../../assets/img/icons/ic_minus.svg')"></app-image>
                                                      <app-image :alt="cart-remove" :data-rewardsshop-action="cart-remove"
                                                            :data-rewardsshop-cart-arrayposition="productArrayIndex"
                                                            :data-rewardsshop-cart-remove-location="route.meta.trackingName"
                                                            :data-rewardsshop-cart-remove-quantity="product?.qty" key=""
                                                            v-else @click="removeItem(product)" class="cursor-pointer trash"
                                                            :src="require('../../assets/img/icons/ic_delete.svg')"></app-image>
                                                </div>
                                                <div class="flex flex-col justify-center items-center">
                                                      <span v-if="!showActions"> {{ product.qty }} x</span>
                                                      <span v-else> {{ product.qty }}</span>
                                                </div>
                                                <div v-if="showActions" class="add">
                                                      <app-image :alt="cart - add" data-rewardsshop-action="cart-add"
                                                            :data-rewardsshop-cart-arrayposition="productArrayIndex"
                                                            :data-rewardsshop-cart-add-quantity="product?.qty"
                                                            :data-rewardsshop-cart-add-location="route.meta.trackingName"
                                                            @click="increaseQntyByOne(product)" class="cursor-pointer"
                                                            :src="require('../../assets/img/icons/ic_add.svg')"></app-image>
                                                </div>
                                          </div>
                                    </div>

                                    <div
                                          class="text-right type-number product__table-actions_subtable-total flex flex-col justify-center items-end">
                                          <h3>{{ thousandSeparator(product.total) }} °P</h3>
                                    </div>
                                    <div class="product__table-actions_subtable-bottomtext my-1">
                                          <div
                                                class="flex flex-row justify-center items-center xs:hidden xxs:hidden sm:hidden md:flex lg:flex">
                                                <div v-if="product?.sold_by != product?.shipped_by">
                                                      <span class="mr-3" v-if="product.sold_by">Verkauf durch: <span
                                                                  class="text-blue">{{ product.sold_by }} </span></span>

                                                      <span v-if="product.shipped_by">Versand durch: <span
                                                                  class="text-blue">{{ product.shipped_by }}</span></span>
                                                </div>

                                                <span
                                                      v-if="product?.sold_by && product?.shipped_by && product.sold_by != '' && product.shipped_by != '' && product?.sold_by === product?.shipped_by">Verkauf
                                                      und Versand durch: <span class="text-blue">{{ product.sold_by }}
                                                      </span></span>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
            <div v-else>
                  <empty-cart></empty-cart>
            </div>
      </section>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import AppImage from "@/components/Image/Image";
import EmptyCart from "@/components/ShoppingCart/EmptyCart";
import { useRoute, useRouter } from "vue-router";
import numbersConvert from "@/helpers/numbersConvert";

export default {
      name: "CartProducts",
      components: {
            EmptyCart,
            AppImage
      },
      props: {
            expressCheckout: {
                  type: Boolean,
                  default: false
            },
            showActions: {
                  type: Boolean,
                  default: true
            },
            productWrapper: ""
      },
      setup(props) {
            const { t } = useI18n();
            const store = useStore();
            const router = useRouter();
            const route = useRoute();

            let module = computed(() => {
                  return props.expressCheckout ? "expressCheckoutCart" : "cart";
            });

            let cart = computed(() => {
                  return store.getters[`${module.value}/cart`];
            });

            const thousandSeparator = (val) => {
                  return numbersConvert.thousandSeparator(val);
            };

            const increaseQntyByOne = (cartItem) => {
                  // Check if increasing the quantity by one will pass the allowed limit
                  if (cartItem.max_qty_allowed && cartItem.qty + 1 > cartItem.max_qty_allowed) {
                        // Notify the user that the max allowed number for this item is being exceeded
                        let notification = {
                              code: "ITEM_LIMITED_AMOUNT",
                              type: "info"
                        };
                        store.dispatch("notification/set", notification);
                  } else {
                        let itemData = {
                              item: {
                                    id: cartItem.id,
                                    qty: 1
                              }
                        };
                        store.dispatch(`${module.value}/updateItem`, itemData);
                  }
            };

            const reduceQntyByOne = (cartItem) => {
                  // If the quantity of the item becomes 0 after reducing it by one then remove the item
                  if (cartItem.qty - 1 <= 0) {
                        removeItem(cartItem);
                  } else {
                        let itemData = {
                              item: {
                                    id: cartItem.id,
                                    qty: -1
                              }
                        };
                        store.dispatch(`${module.value}/updateItem`, itemData);
                  }
            };

            const removeItem = (cartItem) => {
                  if (cartItem.qty == 1) {
                        store.dispatch(`${module.value}/deleteItem`, cartItem);
                  }
                  if (module.value == "expressCheckoutCart") {
                        router.push("/");
                  }
            };

            const productArrayIndex = computed(() => {
                  return store.getters["cart/getProductArrayIndex"];
            });
            return {
                  cart,
                  increaseQntyByOne,
                  removeItem,
                  reduceQntyByOne,
                  thousandSeparator,
                  t,
                  route,
                  productArrayIndex
            };
      }
};
</script>
<style lang="postcss" scoped>
.product__table {
      @apply grid gap-2 grid-flow-row-dense text-left bg-white border-blue-lightest text-black px-4 py-4 mx-0 mt-1 mb-8;
      filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.15)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.12));

      &:last-of-type {
            @apply mb-8;
      }

      @media screen and (max-width: 767px) {
            @apply mx-4;
      }

      @media (min-width: 1px) {
            grid-template-columns: 100%;

            &-imgWrapper {
                  grid-column: 1/-1;

                  &-image {
                        width: 50%;
                        height: auto;
                        margin: 0 auto;
                  }
            }

            &-desc {
                  grid-column: 1/-1;
            }

            &-deliveryinfo {
                  @apply col-span-1;
                  grid-column: 1/-1;
            }

            &-actions {
                  grid-column: 1/-1;

                  &_subtable {
                        @apply grid-cols-2 pt-4;
                        border-color: rgba(0, 0, 0, 0.12);

                        &-buttons {
                              @apply col-span-1;
                              width: 128px;

                              div {
                                    height: 40px;
                              }

                              .add {
                                    @apply bg-blue rounded-full shadow-sm flex items-center justify-center;
                                    height: 40px;
                                    width: 40px;

                                    img {
                                          height: 24px;
                                    }

                                    &:hover {
                                          @apply bg-blue-focus;
                                    }
                              }

                              .minus-trash {
                                    @apply flex items-center justify-center;
                                    height: 40px;
                                    width: 40px;

                                    img {
                                          &.trash {
                                                height: 24px;
                                          }

                                          height: 24px;
                                    }

                                    &.minus {
                                          @apply bg-blue rounded-full shadow-sm flex items-center justify-center;
                                          height: 40px;
                                          width: 40px;

                                          img {
                                                height: 24px;
                                          }

                                          &:hover {
                                                @apply bg-blue-focus;
                                          }
                                    }
                              }
                        }

                        &-total {
                              @apply col-span-4;
                        }

                        &-bottomtext {
                              @apply col-span-5;
                        }
                  }
            }
      }

      @media (min-width: 400px) {
            @apply grid-cols-3;

            &-imgWrapper {
                  @apply col-span-1;

                  &-image {
                        width: 120px;
                        height: 120px;
                  }
            }

            &-desc {
                  @apply col-span-2;
            }

            &-deliveryinfo {
                  @apply col-span-3;
            }

            &-actions {
                  @apply col-span-3;

                  &_subtable {
                        @apply grid-cols-2;

                        &-buttons {
                              @apply col-span-1;
                        }

                        &-total {
                              @apply col-span-4;

                              &-bottomtext {
                                    @apply col-span-5;
                              }

                              h3 {
                                    --text-opacity: 1;
                                    color: #313133;
                                    color: rgba(49, 49, 51, var(--text-opacity));
                                    font-size: 1.5rem;
                                    font-weight: 700;
                                    letter-spacing: 0.009375em;
                                    line-height: 1.5rem;
                                    line-height: 2.5rem;
                                    font-family: PAYBACKLight, Open Sans, Arial, Helvetica Neue, Helvetica, sans-serif;
                              }
                        }
                  }
            }
      }

      @media (min-width: 600px) {
            @apply grid-cols-4;

            &-imgWrapper {
                  @apply col-span-1;

                  &-image {
                        width: 120px;
                        height: 120px;
                  }
            }

            &-desc {
                  @apply col-span-3;
            }

            &-deliveryinfo {
                  @apply col-span-4;
            }

            &-actions {
                  @apply col-span-4;

                  &_subtable {
                        @apply grid-cols-4;

                        &-buttons {
                              @apply col-span-1;
                        }

                        &-total {
                              @apply col-span-4;
                        }

                        &-bottomtext {
                              @apply col-span-5;
                        }
                  }
            }
      }

      @media (min-width: 960px) {
            @apply grid-cols-5;

            &-imgWrapper {
                  @apply col-span-1;

                  &-image {
                        width: 120px;
                        height: 120px;
                  }
            }

            &-desc {
                  @apply col-span-4;
            }

            &-deliveryinfo {
                  @apply col-span-5;
            }

            &-actions {
                  @apply col-span-5;

                  &_subtable {
                        @apply grid-cols-5;

                        &-buttons {
                              @apply col-span-1;
                        }

                        &-total {
                              @apply col-span-4;
                        }

                        &-bottomtext {
                              @apply col-span-5;
                        }
                  }
            }
      }
}

.express {
      .product__table {
            @apply mt-4;

            &-deliveryinfo {
                  @apply bg-yellow-warning text-left p-4 rounded;
                  grid-column: 1/-1;

                  p {
                        @apply text-black mb-0;
                        font-family: "Roboto", sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;

                        letter-spacing: 0.44px;
                  }
            }
      }
}</style>
<style lang="postcss">@media (min-width: 992px) {
      .product__table ul {
            padding-left: 22px;
      }
}

@media (min-width: 600px) {
      .product__table ul {
            padding-left: 10px;
      }
}

@media (min-width: 1px) {
      .product__table ul {
            padding-left: 10px;
      }
}

.express-checkout {
      .product__table {
            @apply mt-4;
      }
}

.product-wrapper {
      @apply mt-8;
}</style>
