<template>
      <div>
            <div v-if="voucherDiscount > 0">
                  <div class="checkout__table-redeemVoucherTable">
                        <div class="col-span-3">
                              {{ t("LBL_GUTSCHEIN") }}
                        </div>
                        <div class="text-right type-caption">- {{ voucherDiscount }} °P</div>
                  </div>
            </div>
            <div v-else-if="!showRedeemVoucherInput" class="text-blue">
                  <span class="voucher__link cursor-pointer" @click="showRedeemVoucher()">{{ t("LBL_REDEEM_PREMIUM_VOUCHER") }}?</span>
            </div>
            <div v-else>
                  <div class="checkout__table-redeemVoucherTable">
                        <div class="col-span-3">
                              <text-input :placeholder="t('Prämiengutschein')" @input="getInput($event)"></text-input>
                        </div>
                        <div class="text-blue flex flex-col justify-center items-center redeemVoucherbuttonDiv">
                              <Button :css-class="'green'" :text="'OK'" class="checkout__table-redeemVoucherTable_redeemButton" @click="validateVoucher"></Button>
                        </div>
                  </div>
                  <div class="error mt-1" v-if="showError">{{ t("ERR_VOUCHER_INVALID") }}</div>
            </div>
      </div>
</template>

<script>
      import Button from "@/components/Button/Button";
      import TextInput from "@/components/Inputs/TextInput";
      import { ref, computed } from "vue";
      import { useStore } from "vuex";
      import { useI18n } from "vue-i18n";
      import EventBus from "@/helpers/event-bus";

      export default {
            name: "Voucher",
            components: {
                  Button,
                  TextInput
            },
            props: {
                  module: {
                        type: String,
                        default: "expressCheckoutCart"
                  }
            },
            setup(props) {
                  const store = useStore();
                  const { t } = useI18n();
                  const showError = ref(false);

                  const showRedeemVoucherInput = ref(false);
                  const redeemVoucherInput = ref("");

                  const voucherDiscount = computed(() => {
                        return store.getters[`${props.module}/voucherDiscount`];
                  });

                  // Handle vouchers
                  const showRedeemVoucher = () => {
                        showRedeemVoucherInput.value = !showRedeemVoucherInput.value;
                  };

                  const getInput = (input) => {
                        if (/^\W$/.test(input.data)) {
                              input.preventDefault();
                              input.target.value = "";
                        }
                        redeemVoucherInput.value = input.target.value;
                  };

                  const validateVoucher = async () => {
                        showError.value = false;
                        let response = null;
                        let params = { code: redeemVoucherInput.value };
                        if (props.module === "expressCheckoutCart") {
                              response = await store.dispatch("cart/validateExpressVoucher", params);
                        } else {
                              response = await store.dispatch("cart/validateVoucher", params);
                        }

                        if (response?.error) {
                              showError.value = true;
                        }
                        await store.dispatch(`${props.module}/get`);
                  };

                  return {
                        showRedeemVoucherInput,
                        redeemVoucherInput,
                        voucherDiscount,
                        showError,
                        showRedeemVoucher,
                        validateVoucher,
                        getInput,
                        t
                  };
            }
      };
</script>
<style lang="postcss" scoped>
      .checkout__table {
            @apply grid grid-cols-2  gap-4 grid-flow-row-dense bg-white p-4 mt-1;

            &-redeemVoucherTable {
                  @apply grid grid-cols-4  gap-0 grid-flow-row-dense bg-white;

                  &_redeemButton {
                        padding: 10px;
                        height: 36px;
                        width: 50px;
                        @apply ml-2 mt-2;
                  }
            }
      }
      .redeemVoucherbuttonDiv {
            .green {
                  height: 100%;
                  width: 100%;
            }
      }
      .error {
            @apply text-red-dark;
      }
      .voucher__link {
            @apply cursor-pointer;
      }
</style>
