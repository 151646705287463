<template>
      <section>
            <div class="page-content-lightestblue-main" data-rewardsshop-action="no-orders">
                  <div class="page-content-lightestblue-main__container">
                        <div class="page-content-lightestblue-main__container-colLeft">
                              <h2>{{ t("Keine Prämie im Warenkorb") }}</h2>
                              <div class="py-3">
                                    {{ t("Sie haben aktuell keine Prämie im Warenkorb. Wählen Sie gleich eine tolle Prämie aus unserem vielfältigen Angebot aus.") }}
                              </div>

                              <div class="py-3">
                                    <Button :css-class="'red'" :text="t('Prämie aussuchen')" class="w-full" @click="goToLandingPage()"></Button>
                              </div>
                        </div>
                        <div class="page-content-lightestblue-main__container-colRight">
                              <app-image :alt="emppty-cart" :src="require('../../assets/img/empty-cart-picture-data.png')"></app-image>
                        </div>
                  </div>
            </div>
      </section>
</template>

<script>
      import Button from "@/components/Button/Button";
      import AppImage from "@/components/Image/Image";
      import { useRoute, useRouter } from "vue-router";
      import { useStore } from "vuex";
      import { useI18n } from "vue-i18n";

      export default {
            name: "EmptyCart",
            components: {
                  AppImage,
                  Button
            },
            setup() {
                  const router = useRouter();
                  const route = useRoute();
                  const store = useStore();
                  const { t } = useI18n();
                  const orderNumber = route.params.order_number;
                  const goToLandingPage = async () => {
                        store.dispatch("catalog/setFilters", {});
                        store.dispatch("catalog/emptyProductsList");
                        router.push("/");
                  };
                  return {
                        goToLandingPage,
                        orderNumber,
                        t
                  };
            }
      };
</script>
<style lang="postcss" scoped>
      .page-content-lightestblue-main {
            @apply bg-blue-lighter text-black-light;
            &__container {
                  @apply bg-blue-lightest py-4 px-12 pb-0;
            }
      }
</style>
