import { computed, watchEffect, watch, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import EventBus from "@/helpers/event-bus";
import numbersConvert from "@/helpers/numbersConvert";
import securePay from "@/composables/securePay";

export function useCheckout(module = "expressCheckoutCart") {
  const store = useStore();

  const { t } = useI18n();

  const alertText = ref("");
  const alertTextAdditionalPayment = ref(``);
  const isValidPointsAmount = ref(false);
  const applicableDiscount = ref("");
  const isValid = ref(true);

  const isLoggedIn = computed(() => {
    return store.getters["account/isLoggedIn"];
  });

  let customerPoints = computed(() => {
    return store.getters["auth/customerPoints"];
  });

  const minPoints = computed(() => {
    return store.getters[`${module}/minPoints`];
  });
  const pointsDiscount = computed(() => {
    return store.getters[`${module}/pointsDiscount`];
  });
  const grandTotalWithVoucherDiscount = computed(() => {
    return store.getters[`${module}/grandTotalWithVoucherDiscount`];
  });

  const voucherActionType = computed(() => {
    return store.getters[`${module}/voucherActionType`];
  });

  const voucherDiscount = computed(() => {
    return store.getters[`${module}/voucherDiscount`];
  });

  const additionalPayment = computed(() => {
    return store.getters[`${module}/additionalPayment`];
  });

  // const paymentUrl = computed(() => {
  //   return store.getters["payment/getPaymentUrl"];
  // });

  const initUserInputValue = async (pointsToRedeemValue) => {
    // If there is a discount already applied then we check the validity of that
    // otherwise we check the validity of the total basket sum and check if we can redeem that amount
    if (isLoggedIn.value) {
      await onPointsRedeem(pointsToRedeemValue, false);
    }
  };

  const onPointsRedeem = async (amount, showAlertText = true) => {
    isValidPointsAmount.value = false;
    if (isLoggedIn.value) {
      const response = checkValidity(amount);

      if (showAlertText) {
        alertText.value = response.alert;
      }
      // setTimeout(async () => {
      //   if (paymentUrl?.value && paymentUrl?.value?.url === '') {
      //     response.isValid.value = true
      //   }

      applicableDiscount.value = response?.applicableDiscount;
      isValidPointsAmount.value = response?.isValid.value;

      // If the validation is successful or
      // if the validation is not successful but we want to send a request to backend with the adjusted amount
      if (response.isValid.value) {
        // Sending the request to redeem points
        await store.dispatch(`${module}/redeemPoints`, { redeemPoints: response.applicableDiscount, module: module });
        if (module === 'expressCheckoutCart' && response.applicableDiscount > 0) {
          let params = {
            mode: 'express-checkout',
            paymentValue: 0,
            iframeUrl: 'payment/express-checkout-url'
          };
          await store.dispatch("payment/getPaymentUrl", params);
          securePay.initFinished = false;
          let iframe = document.getElementById("securepay");
          if (iframe !== null) {
            iframe.onload = function () {
              if (params.mode !== "cashdiscount") {
                setTimeout(securePay.initSecurePay, 300);
              }
              window.addEventListener("message", securePay.receiveMessage);
            };
          }
        }
      }
      // }, 500);
      if (additionalPayment.value / 100 < 1 && additionalPayment.value / 100 !== 0) {
        const link = '<a href="https://praemien.payback.at/praemien/praemienaktionen/zuzahlung" target="_blank"><u>hier</u></a>'
        alertTextAdditionalPayment.value = `Eine Zuzahlung ist erst ab 1,00 € möglich. Sie müssen dazu <span style="color: #7c0000; font-weight: bold">${numbersConvert.thousandSeparator(grandTotalWithVoucherDiscount.value - 100)} </span> °P einlösen.
                                            Weitere Informationen zur Zuzahlung finden Sie ${link}.`
        isValid.value = false

      } else {
        alertTextAdditionalPayment.value = "";
      }
      EventBus.emit("updatedAlertTextAdditionalPayment", { alertTextAdditionalPayment: alertTextAdditionalPayment.value, isValid: isValid.value });

      EventBus.emit("updatedAlertText", { alertText: response?.alert, isValid: response?.isValid.value });



    }
  };

  const checkVoucherTypeFixed = (type, userEnteredValue) => {
    switch (type) {
      case "MSG_NOT_ENOUGH_POINTS_IN_ACCOUNT":
        return voucherActionType.value == "Fixed" && voucherDiscount.value + customerPoints.value < minPoints.value;

      case "MSG_REDEEM_AT_LEAST_MIN_POINTS":
        return voucherActionType.value == "Fixed" && voucherDiscount.value + userEnteredValue < minPoints.value;

      case "MSG_REDEEM_UP_TO_CUSTOMER_POINTS":
        return voucherActionType.value == "Fixed" && userEnteredValue > customerPoints.value;


    }
  };

  const checkValidity = (input) => {
    // alert is the message we should display to the user in case the entered amount doesn't meet the restrictions
    let alert = "";

    // Adjusted amount that meets the criteria
    let applicableDiscount = "";

    switch (true) {
      case (customerPoints.value < minPoints.value && voucherActionType.value != "Fixed") || checkVoucherTypeFixed("MSG_NOT_ENOUGH_POINTS_IN_ACCOUNT"):
        //@todo Sascha will provide you with the correct message
        alert = t("MSG_NOT_ENOUGH_POINTS_IN_ACCOUNT", {
          customerPoints: customerPoints.value,
          minPoints: minPoints.value,
        });
        applicableDiscount = 0;
        isValid.value = false;
        break;
      case (input < minPoints.value && voucherActionType.value != "Fixed") || checkVoucherTypeFixed("MSG_REDEEM_AT_LEAST_MIN_POINTS", input):
        alert = t("MSG_REDEEM_AT_LEAST_MIN_POINTS", { input: input, minPoints: voucherActionType.value === "Fixed" ? minPoints.value - voucherDiscount.value : minPoints.value });
        applicableDiscount = minPoints.value;
        isValid.value = false;
        break;
      case (input > customerPoints.value && voucherActionType.value != "Fixed") || checkVoucherTypeFixed("MSG_REDEEM_UP_TO_CUSTOMER_POINTS", input):
        alert = t("MSG_REDEEM_UP_TO_CUSTOMER_POINTS", { customerPoints: customerPoints.value });
        applicableDiscount = customerPoints.value;
        isValid.value = false;
        break;
      case input > grandTotalWithVoucherDiscount.value:
        alert = t("MSG_REDEEM_UP_TO_TOTAL_BASKET_SUM", { input: input, totalSum: grandTotalWithVoucherDiscount.value });
        applicableDiscount = grandTotalWithVoucherDiscount.value;
        isValid.value = false;
        break;
      case input === grandTotalWithVoucherDiscount.value:
        alert = t("MSG_WITHOUT_CO_PAYMENT");
        applicableDiscount = grandTotalWithVoucherDiscount.value;
        isValid.value = true;
        break;
      default:
        applicableDiscount = input;
        alertText.value = "";
        isValid.value = true;
    }

    return { alert: alert, isValid: isValid, applicableDiscount: applicableDiscount };
  };
  watch(
    () => grandTotalWithVoucherDiscount.value || pointsDiscount.value,
    async (current, prev) => {
      setTimeout(async () => {
        if (current != prev) {
          if (customerPoints.value < grandTotalWithVoucherDiscount.value) {
            await onPointsRedeem(Number(customerPoints.value), alertText);
          } else {
            await onPointsRedeem(Number(current), alertText);
          }

        }
      }, 0);
    }
  );

  return {
    alertText,
    alertTextAdditionalPayment,
    isValidPointsAmount,
    applicableDiscount,
    initUserInputValue,
    onPointsRedeem,
    isValid,
    checkValidity,
    pointsDiscount
  };
}
